import { bannerPaddingVariants } from "components/Banner";
import { useTranslation } from "react-i18next";
import Section from "components/Section";
import Header from "components/Header";
import Layout from "components/Layout";
import Link from "next/link";
import cx from "classix";

export default function NotFoundPage() {
  const { t } = useTranslation();

  const contacts = [
    {
      text: t("quebec"),
      phoneNumberText: "418 626.2344",
      phoneNumberLink: "tel:+14186262344",
    },
    {
      text: t("montreal"),
      phoneNumberText: "514 670.2344",
      phoneNumberLink: "tel:+15146702344",
    },
    {
      text: t("toll_free"),
      phoneNumberText: "1 877 624.2344",
      phoneNumberLink: "tel:+18776242344",
    },
  ];

  return (
    <Layout title={t("not_found") || undefined}>
      <Header
        title={t("not_found")}
        className="bg-[url('/images/persons/charles-olivier-demers.png')]"
      />
      <main className="bg-red-500">
        <Section
          className={cx(
            "bg-red-500 text-white flex flex-col gap-6",
            bannerPaddingVariants.defaultWithTitle
          )}
        >
          <h1 className="font-bold text-r-5xl leading-none">
            {t("not_found_title")}
          </h1>
          <p className="text-r-lg leading-1.2">{t("not_found_text")}</p>
          <ul className="text-r-lg leading-1.2 space-y-1">
            {contacts.map(({ text, phoneNumberText, phoneNumberLink }) => (
              <li key={phoneNumberLink}>
                {text}{" "}
                <Link className="underline" href={phoneNumberLink}>
                  {phoneNumberText}
                </Link>
              </li>
            ))}
            <li>
              <Link
                className="underline text-r-xl font-bold leading-1.1"
                href="mailto:info@afiexpertise.com"
              >
                info@afiexpertise.com
              </Link>
            </li>
          </ul>
        </Section>
      </main>
    </Layout>
  );
}
